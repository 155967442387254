import React from "react";
import { Link } from "react-scroll";
import VideoMp4 from "../assets/videos/hero0.mp4";

export const Hero = () => {

  return (
    <section name="home" className="relative w-full mt-[80px] h-screen flex flex-col justify-between bg-black">
      <div className="absolute z w-full h-full">
        <video
          id="bg_video"
          autoPlay={true}
          muted loop
          playsInline={true}
          className="absolute -z-2 w-full h-full object-cover bg-black opacity-80"
        >
          <source src={VideoMp4} type="video/mp4" />
        </video>
      </div>

      <div className="absolute top-0 left-0 bottom-0 right-0 bg-black opacity-50" />

      <div className="absolute h-full grid md:grid-cols-2 w-full mx-auto px-4 z-2">
        <div className="flex flex-col justify-center md:items-start w-full px-4 pb-8">
          <div className="p-4 rounded-[12px]">
            <h2 className="text-6xl py-4 my-16 font-bold text-white">
              Valiant
            </h2>
            <p className="text-2xl text-white">
              The Future of AI Research Workflow
            </p>
          </div>
          <Link className="hover:cursor-pointer text-white bg-transparent py-3 ml-4 px-6 sm:w-[60%] border-4 text-center hover:bg-white hover:text-gray-800 " to="about" smooth={true} offset={0} duration={500}>
            Learn More
          </Link>
        </div>
      </div>
    </section>
  );
}
